import __ from '@/shared/helpers/__'
import _ from 'lodash';
import { LOCALE_ERROR } from "@/shared/constants/locales.constants";
import Field from "@/shared/classes/form/field";
import ArrayField from "@/shared/classes/form/fields/array-field";
import { getLanguagesByVersion } from "@/config";
import { getVersion } from "@/shared/helpers/version.helper";


// Validates:
// - required fields
// - translatable fields filled in all languages
export const validateFields = (
  globalData: any,
  fields?: Field[],
  params?: { onError?: () => void, languages?: string[], onlyRegionLangRequired?: boolean }
) => {
  const errors: Record<string, any> = {};
  const languages = params?.languages || getLanguagesByVersion();
  const region = getVersion();

  const addError = (key: string, error: string | boolean) => {
    if (typeof error === 'boolean') return errors[key] = error;
    if (!errors[key]) errors[key] = { has: true, messages: new Set() };
    errors[key].messages.add(error);
  }

  const checkField = (field: Field, data: any, prefix = '', index: number | null = null) => {
    if (field.hidden) return;
    if (typeof field.visibleIf === 'function' && !field.visibleIf(globalData, index)) return;

    const errorKey = prefix ? `${prefix}.${field.key}` : field.key;
    const fieldValue = _.get(data, field.key);

    if (field.type === 'array') {
      return fieldValue?.forEach((entry: any, index: number) => {
        (field as ArrayField).children.forEach((child: any) => {
          checkField(child, entry, `${prefix}${prefix ? '.' : ''}${field.key}.${index}`, index);
        });
      });
    }

    // Translatable fields validation
    if (field.translatable) {
      const hasValueInAnyLanguage = languages.some(lang => !!fieldValue[lang]);
      languages.forEach(lang => {
        if (params?.onlyRegionLangRequired && lang !== region) return;

        if (field.required && !fieldValue[lang]) {
          addError(`${errorKey}.${lang}`, __('validation.required'));
          addError(`${LOCALE_ERROR}.${lang}`, true);
        }
        if (hasValueInAnyLanguage && !fieldValue[lang] && !params?.onlyRegionLangRequired) {
          addError(`${errorKey}.${lang}`, __('validation.fill-all-lang'));
          addError(`${LOCALE_ERROR}.${lang}`, true);
        }
      })
    }
    // Required non-translatable fields validation
    else if (field.required && !fieldValue) {
      addError(errorKey, __('validation.required'));
    }
  }

  fields?.forEach(field => checkField(field, globalData));

  const errorEntries = Object.entries(errors);
  // Form valid
  if (!errorEntries.length) return false;

  // Form invalid
  if (params?.onError) params.onError();
  return errorEntries.reduce((s, [key, value]) => {
    const errorValue = typeof value === 'boolean' ?
      value :
      {
        ...value,
        count: value.messages.size,
        messages: Array.from(value.messages)
      };
    return { ...s, [key]: errorValue };
  }, {});
}

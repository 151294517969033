import { IFormError, ISubmit, Positions } from '@/shared/interfaces/classes/form.interfaces'
import Field from '@/shared/classes/form/field'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import localeHelper from '@/shared/helpers/locale.helper'
import __ from '@/shared/helpers/__'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import FormGroup from '@/shared/classes/form/group'
import { AxiosError } from 'axios'
import { LOCALE_ERROR, LOCALES } from '@/shared/constants/locales.constants'
import _ from "lodash";
import { FieldTypes } from "@/shared/components/form/field-types";
import { validateFields } from "@/shared/helpers/validate";
import { getAdditionalLanguagesByVersion, getLanguagesByVersion } from "@/config";

export default class FormBase {
  formId: string = Math.random().toString(36).slice(2)
  uuid!: string
  endpoint!: string
  fields: Field[] = []
  inline: boolean = false
  errors: IFormError = {}
  responseType: null|string = null
  data: any = {}
  beforeSubmit!: Promise<any>|any
  currentLanguage: string = localeHelper.getLocale()
  withoutData: boolean = false
  method: HttpMethod = HttpMethod.POST
  injectValues!: any
  updateInjectValues!: (model: Model<IModelResponse>|any) => object
  model!: any
  entry!: any
  onSetEntry!: (entry: any) => void
  autocomplete: string = 'off'
  spacing: boolean = false
  loading: boolean = false
  files: boolean = false
  initialCall: boolean = false
  translatable: boolean = false
  translatableLoading: boolean = false
  additionalTranslatableLanguages: boolean = false
  initialValues: any = {}
  groups: FormGroup[] = []
  submit: ISubmit|boolean = {
    color: 'primary',
    class: '',
    text: __('form.save'),
    position: Positions.left,
    outlined: false,
    depressed: false,
  }
  appendedEndpointString: string = ''
  appendUuidOnSubmit: boolean = true
  changeDataBeforeSubmit!: (data: any) => any
  onSuccess!: (data: any, entry?: any, response?: any) => void
  onError!: () => void
  unsetNonFieldsValues = true
  validate!: (data: any) => boolean | Record<string, any>
  showDefaultValidationError: boolean = false
  onEntryLoaded!: (entry: any) => void

  setAppededEndpointString(appended: string): this {
    this.appendedEndpointString = appended
    return this
  }
  setResponseType(responseType: string): this {
    this.responseType = responseType
    return this
  }
  setAppendUuidOnSubmit(appended: boolean): this {
    this.appendUuidOnSubmit = appended
    return this
  }

  setFormId(id: string): this {
    this.formId = id
    return this
  }

  setUuid(uuid: string): this {
    this.uuid = uuid
    return this
  }

  setEndpoint(endpoint: string): this {
    this.endpoint = endpoint
    return this
  }

  setInline(inline: boolean): this {
    this.inline = inline
    return this
  }

  setInitialCall(initial: boolean): this {
    this.initialCall = initial
    return this
  }

  setFields(fields: Field[]): this {
    this.fields = fields
    return this
  }

  addField(field: Field): this {
    this.fields = [...this.fields, field]
    return this
  }

  setInjectValues(injectValues: any): this {
    this.injectValues = injectValues
    return this
  }

  setUpdateInjectValues(updateInjectValues: (model: Model<IModelResponse>|any) => object): this {
    this.updateInjectValues = updateInjectValues
    return this
  }

  setModel(model: any): this {
    this.model = model
    return this
  }

  setLoading(loading: boolean): this {
    this.loading = loading
    return this
  }

  setFiles(files: boolean): this {
    this.files = files
    return this
  }

  setSubmit(submit: ISubmit|boolean): this {
    this.submit = submit
    return this
  }

  setOnSuccess(onSuccess: (data: any, entry?: any, response?: any) => void): this {
    this.onSuccess = onSuccess
    return this
  }

  setOnError(onError: () => void): this {
    this.onError = onError
    return this
  }

  setData(data: any): this {
    this.data = data
    return this
  }

  setErrors(errors: IFormError): this {
    this.errors = errors
    return this
  }

  changeError(key: string, value: any) {
    this.errors[key] = value
  }

  setMethod(method: HttpMethod): this {
    this.method = method
    return this
  }

  setEntry(entry: any): this {
    this.entry = entry
    this.onSetEntry && this.onSetEntry(entry)
    return this
  }

  setOnSetEntry(onSetEntry: (entry: any) => void): this {
    this.onSetEntry = onSetEntry
    return this
  }

  setWithoutData(withoutData: boolean): this {
    this.withoutData = withoutData
    return this
  }

  setTranslatable(translatable: boolean): this {
    this.translatable = translatable
    return this
  }

  setTranslatableLoading(translatableLoading: boolean): this {
    this.translatableLoading = translatableLoading
    return this
  }

  setAdditionalTranslatableLanguages(additionalTranslatableLanguages: boolean): this {
    this.additionalTranslatableLanguages = additionalTranslatableLanguages
    return this
  }

  setCurrentLanguage(currentLanguage: string): this {
    this.currentLanguage = currentLanguage
    return this
  }

  setSpacing(spacing: boolean): this {
    this.spacing = spacing
    return this
  }

  setAutocompleteEnabled(): this {
    this.autocomplete = ''
    return this
  }

  setBeforeSubmit(beforeSubmit: Promise<any>|any): this {
    this.beforeSubmit = beforeSubmit
    return this
  }

  setInitialValues(initialValues: any): this {
    this.initialValues = initialValues
    return this
  }

  setGroups(groups: FormGroup[]): this {
    this.groups = groups
    return this
  }

  setChangeDataBeforeSubmit(changeDataBeforeSubmit: (data: any) => any): this {
    this.changeDataBeforeSubmit = changeDataBeforeSubmit
    return this
  }

  setUnsetNonFieldsValues(unsetNonFieldsValues: boolean): this {
    this.unsetNonFieldsValues = unsetNonFieldsValues
    return this
  }

  setValidate(
    validate: boolean | ((data: any) => boolean | Record<string, any>),
    params?: { onlyRegionLangRequired?: boolean }
  ): this {
    if (typeof validate === 'boolean' && validate) {
      const defaultValidationFunc = (data: any) => {
        const errors = validateFields(data, this.fields, {
          languages: this.additionalTranslatableLanguages ? getAdditionalLanguagesByVersion() : getLanguagesByVersion(),
          ...(params || {})
        })
        if (Object.keys(errors).length) {
          this.setErrors(errors)
          return false
        }

        return true
      }
      this.showDefaultValidationError = true
      this.validate = defaultValidationFunc
    }
    else if (typeof validate === 'function') {
      this.showDefaultValidationError = false
      this.validate = validate
    }
    return this
  }

  setShowDefaultValidationError(showDefaultValidationError: boolean): this {
    this.showDefaultValidationError = showDefaultValidationError
    return this
  }

  setOnEntryLoaded(onEntryLoaded: (entry: Model<IModelResponse>|any) => void): this {
    this.onEntryLoaded = onEntryLoaded
    return this
  }

  public catchErrors(error: AxiosError<any>) {
    this.onError && this.onError()
    if (! error.response) return

    if (error.response.status === 422) {
      error.response.data.errors && this.parseErrors(error.response.data.errors)
    }
  }

  private parseErrors(errors: any) {
    const formErrors: IFormError = {}
    Object.keys(errors).forEach((key: string) => {
      formErrors[key] = {
        has: true,
        count: errors[key].length,
        messages: errors[key]
      }

      Object.keys(LOCALES).forEach((localeKey: string) => {
        if (key.match(`\\.\\b${localeKey}`)) {
          formErrors[`${ LOCALE_ERROR }.${ localeKey }`] = true
        }
      })
    })

    this.setErrors(formErrors)
  }

  public generateDataForValidation() {
    let data = _.cloneDeep(this.data)

    const recursive = (key: string, found?: Field | any, parentIndex: number | null = null) => {
      if (!found) found = this.fields.find((field: Field) => field.key === key)
      if (
        (!found && this.unsetNonFieldsValues) || (found && !found.visibleIf(data, parentIndex) && this.unsetNonFieldsValues)
      ) _.unset(data, key)

      if (found?.type === FieldTypes.array) {
        _.get(data, key).forEach((value: any, index: number) => {
          Object.keys(value).forEach((childKey: string) => {
            const childFound = found.children.find((field: Field) => field.key === childKey)
            recursive(`${ key }.${ index }.${ childKey }`, childFound, index)
          })
        })
      }

      if (found?.type === FieldTypes.object) {
        if (!_.get(data, key, null)) return
        Object.keys(_.get(data, key)).forEach((childKey: string) => {
          const childFound = found.children.find((field: Field) => field.key === childKey)
          recursive(`${ key }.${ childKey }`, childFound)
        })
      }
    }

    Object.keys(data).forEach((key: string) => recursive(key))

    return data
  }
}

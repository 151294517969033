import { FieldTypes } from '@/shared/components/form/field-types'

export enum FieldSizes {
  full = 'full',
  half = 'half',
  quarter = 'quarter',
  threeQuarters = 'three-quarters',
  oneTwelfth = '1-12',
  twoTwelfth = '2-12',
  twoHalfTwelfth = '2-5-12',
  threeTwelfth = '3-12',
  threeHalfTwelfth = '3-5-12',
  fourTwelfth = '4-12',
  fiveTwelfth = '5-12',
  sevenTwelfth = '7-12',
  eightTwelfth = '8-12',
  tenTwelfth = '10-12',
  elevenTwelfth = '11-12',
}

export default class Field {
  type: FieldTypes = FieldTypes.text
  size: FieldSizes = FieldSizes.full
  underlined: boolean = false
  key: string = ''
  entryKey: string | null = null
  title!: string | null
  hint: string | null = null
  classes: string = ''
  meta: any = {}
  value: any = ''
  placeholder!: string
  group!: string
  fieldGroup!: string
  dense: boolean = false
  disabled: (() => boolean)|boolean = false
  hideDetails: boolean = false
  translatable: boolean = false
  visibleIf: (values: any, index: number|null) => boolean = () => true
  onChange!: (value?: any, fullKey?: string) => void
  onEnter: () => void = () => {}
  append!: any
  appendOuter!: any
  tooltip!: string
  beforeChange!: (value: any) => any
  rows: number | null = null
  component!: any
  hidden: boolean = false
  required: boolean = false
  getInitialObjectKey: any = null
  readonly: boolean = false
  appendIcon: string | null = null

  setType(type: FieldTypes): this {
    if (type === FieldTypes.file) {
      this.value = null
    }
    this.type = type
    return this
  }

  setKey(key: string): this {
    this.key = key
    return this
  }

  setEntryKey(entryKey: string): this {
    this.entryKey = entryKey
    return this
  }

  setTitle(title: string): this {
    this.title = title
    return this
  }

  setGetInitialObjectKey(getInitialObjectKey: (key: string) => string): this {
    this.getInitialObjectKey = getInitialObjectKey
    return this
  }

  setValue(value: any): this {
    this.value = value
    return this
  }

  setDense(dense: boolean): this {
    this.dense = dense
    return this
  }

  setHideDetails(hideDetails: boolean): this {
    this.hideDetails = hideDetails
    return this
  }

  setOnEnter(onEnter: () => void): this {
    this.onEnter = onEnter
    return this
  }

  setSize(size: FieldSizes): this {
    this.size = size
    return this
  }

  setDisabled(disabled: boolean|(() => boolean)): this {
    this.disabled = disabled
    return this
  }

  setMeta(meta: any): this {
    this.meta = meta
    return this
  }

  setVisibleIf(visibleIf: (values: any, index: number|null) => boolean): this {
    this.visibleIf = visibleIf
    return this
  }

  setAppend(append: any): this {
    this.append = append
    return this
  }

  setAppendOuter(appendOuter: any): this {
    this.appendOuter = appendOuter
    return this
  }

  setOnChange(onChange: (value?: any, fullKey?: string) => void): this {
    this.onChange = onChange
    return this
  }

  setTranslatable(translatable: boolean): this {
    this.translatable = translatable
    return this
  }

  setHint(hint: string): this {
    this.hint = hint
    return this
  }

  setBeforeChange(beforeChange: (value: any) => any): this {
    this.beforeChange = beforeChange
    return this
  }

  setPlaceholder(placeholder: string): this {
    this.placeholder = placeholder
    return this
  }

  setGroup(group: string): this {
    this.group = group
    return this
  }

  setFieldGroup(fieldGroup: string): this {
    this.fieldGroup = fieldGroup
    return this
  }

  setTooltip(tooltip: string): this {
    this.tooltip = tooltip
    return this
  }

  setClasses(classes: string): this {
    this.classes = classes
    return this
  }

  setRows(rows: number): this {
    this.rows = rows
    return this
  }

  setComponent(component: any): this {
    this.component = component
    return this
  }

  setHidden(hidden: boolean): this {
    this.hidden = hidden
    return this
  }

  isRequired(): this {
    this.required = true
    return this
  }

  setRequired(required: boolean): this {
    this.required = required
    return this
  }

  isUnderlined(): this {
    this.underlined = true

    return this
  }

  setReadonly(readonly: boolean): this {
    this.readonly = readonly

    return this
  }

  setAppendIcon(appendIcon: string | null): this {
    this.appendIcon = appendIcon

    return this
  }
}

































































import { Component, Prop } from 'vue-property-decorator';
import FormField from '@/shared/components/form/FormField.vue'
import LanguageChange from '@/shared/components/form/LanguageChange.vue'
import __ from '@/shared/helpers/__'
import Group from '@/shared/components/form/Group.vue'
import AbstractForm from "@/shared/classes/form/abstract-form";

@Component({
  components: {
    Group,
    LanguageChange,
    FormField,
  },
  methods: {
    __,
  },
})
export default class Form extends AbstractForm {
  @Prop() submitOnMount!: boolean
  @Prop() goBack!: () => void
  @Prop() closeBtn!: boolean

  mounted() {
    if (this.submitOnMount) this.submit()
  }
}
